
<template>
  <div class="account-page">
    <is-loading v-if="isLoading" />
    <section v-else class="">
      <div class="row gy-3">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-account />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-settings />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-password />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabSettings from "./UserEditTabSettings.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  name: "user-edit",
  components: {
    IsLoading,
    UserEditTabAccount,
    UserEditTabSettings,
    UserEditTabPassword,
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
    })
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
